<template>
    <v-form ref="dependents">
        <form-section
            :sectionTitle="$t('applications.dependents')"
            wideFirstCol
        >
            <template v-slot:col-1>
                <div v-for="dependent in entitiesData" :key="dependent.id">
                    <control-tool-bar
                        :title="$t('applications.dependent')"
                        :removeable="dependent.removeable"
                        @remove="removeEntity(dependent.id)"
                    ></control-tool-bar>
                    <applicant-dependent
                        :modelData="dependent"
                        @update="update(dependent.id, 'root', $event)"
                    ></applicant-dependent>
                </div>
                <v-layout
                    class="mt-1"
                    v-if="entitiesData.length < MAX_DEPENDENTS"
                >
                    <v-btn
                        color="secondary"
                        class="mb-2"
                        dark
                        @click="createEntity('dependent')"
                    >
                        <v-icon left>add_circle</v-icon
                        >{{ $t("applications.add-dependent") }}
                    </v-btn>
                </v-layout>
            </template>
        </form-section>
    </v-form>
</template>

<script>
import InteractsWithEntities from "@components/application/mixins/InteractsWithEntities";

export default {
    name: "applicant-dependents",
    mixins: [InteractsWithEntities],
    components: {
        ApplicantDependent: () => import("./ApplicantDependent"),
        ControlToolBar: () => import("./ControlToolBar"),
    },
    props: {},
    computed: {},

    data() {
        return {
            MAX_DEPENDENTS: 10,
            entityIdKey: "id",
            name: this.$t("applications.dependents"),
        };
    },
    watch: {},
    methods: {
        validate() {
            return this.$refs["dependents"].validate();
        },
    },
};
</script>

<style scoped>
</style>